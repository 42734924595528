import React, { useEffect, useState } from 'react'
import { supabaseBrowserClient } from '@/utils/supabase-browser-client'
import { useRouter } from 'next/router'
import { CustomLoader } from '@/components/blocks/custom-loader'

function ProtectedPage(WrappedComponent: React.FC) {
  return function AuthComponent(props: any) {
    const router = useRouter()
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      const getSession = async () => {
        const {
          data: { session },
        } = await supabaseBrowserClient.auth.getSession()
        setIsAuthenticated(!!session)
        setIsLoading(false)

        if (!session) {
          router.replace(`/login?redirect=${router.pathname}`)
        }
      }

      getSession()
    }, [])

    if (isLoading) {
      return (
        <div className='flex items-center justify-center px-4 py-8'>
          <CustomLoader />
        </div>
      )
    }

    if (!isAuthenticated) {
      return null // or a loading indicator
    }

    return <WrappedComponent {...props} />
  }
}

export default ProtectedPage
