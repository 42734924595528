// React
import React, { useState, useCallback, useRef } from 'react'

// Next
import dynamic from 'next/dynamic'

// Utils
import { useSession } from '@/utils/auth/user-context'
import { saveMemeResult } from '@/utils/ui/api-requests/save-meme-result'
import { downloadGIF } from '@/utils/ui/meme/file'
import { createGIFBlob } from '@/utils/meme/gifs'

// Components
import { MemeEditor } from '@/components/memes/editor'
import { ImageDownloadButton } from '@/components/memes/actions/image-download-button'
import { GifDownloadButton } from '@/components/memes/actions/gif-download-button'
import { EditableGif } from '@/components/memes/templates/editable-gif'
import { CopyButton } from '@/components/memes/actions/copy-button'
import { ShareButton } from '@/components/memes/actions/share-button'
import { StarButton } from '@/components/memes/actions/star-button'
import { UnStarButton } from '@/components/memes/actions/unstar-button'
import { Button } from '@/components/ui/button'

const EditableMeme = dynamic(
  () => import('@/components/memes/templates/editable-meme'),
  { ssr: false }
)

const EditableMemeMobile = dynamic(
  () => import('@/components/memes/templates/editable-meme-mobile'),
  { ssr: false }
)

// Types
import { BaseCaption, Caption, MemeFormat } from '@supermeme-ai/types'

// External
import { toast } from 'react-hot-toast'
import * as Sentry from '@sentry/browser'
import { Dialog } from '@headlessui/react'
import { isDesktop, isSafari } from 'react-device-detect'
import { PenSquare, XIcon } from 'lucide-react'

type GeneratedMemeProps = {
  memeFormat: MemeFormat
  imageSrc: string
  initialCaptions: Caption[]
  initialResultId: number
  watermark: string
  maxDimension: number
  initialHeader?: BaseCaption
  initialFooter?: BaseCaption
  imageWidth: number
  imageHeight: number
  isStarredMeme: boolean
  starUnstarCallback: () => void
}

const GeneratedMeme: React.FC<GeneratedMemeProps> = ({
  memeFormat,
  imageSrc,
  initialCaptions,
  initialResultId,
  maxDimension,
  watermark,
  initialHeader,
  initialFooter,
  imageWidth,
  imageHeight,
  isStarredMeme = false,
  starUnstarCallback,
}) => {
  const session = useSession()
  const [resultId, setResultId] = useState(initialResultId)
  const [isSharing, setIsSharing] = useState(false)
  const [shareImageAction, setShareImageAction] = useState(false)

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadImageAction, setDownloadImageAction] = useState<
    'facebook' | 'instagram' | 'linkedin' | 'x' | 'default' | null
  >(null)

  const [isCopying, setIsCopying] = useState(false)
  const [copyImageAction, setCopyImageAction] = useState(false)

  const [captions, setCaptions] = useState<Caption[]>(initialCaptions)
  const [header, setHeader] = useState<BaseCaption>(initialHeader)
  const [footer, setFooter] = useState<BaseCaption>(initialFooter)
  const [isEditing, setIsEditing] = useState(false)
  const downloadRef = useRef(null)

  const downloadMemeFile = useCallback(
    async (format: 'facebook' | 'instagram' | 'linkedin' | 'x' | 'default') => {
      try {
        setIsDownloading(true)
        setDownloadImageAction(format)
        await saveMemeResult(
          session.access_token,
          resultId,
          captions,
          header,
          footer
        )
        setDownloadImageAction(null)
      } catch (e) {
        Sentry.captureException(e)
        toast.error(
          'An error occurred while trying to download the meme. Please try again.'
        )
      } finally {
        setIsDownloading(false)
      }
    },
    [imageSrc, watermark, captions, header, footer]
  )

  const downloadGifFile = useCallback(async () => {
    try {
      setIsDownloading(true)
      const data = await createGIFBlob(
        imageSrc,
        downloadRef.current.clientWidth,
        downloadRef.current.clientHeight,
        watermark,
        header,
        footer,
        captions
      )

      if (!data) {
        throw new Error('Failed to get blob')
      }

      downloadGIF(data)
      saveMemeResult(session.access_token, resultId, captions, header, footer)
    } catch (e) {
      Sentry.captureException(e)
      toast.error(
        'An error occurred while trying to download the meme. Please try again.'
      )
    } finally {
      setIsDownloading(false)
    }
  }, [memeFormat, downloadRef, imageSrc, watermark, captions, header, footer])

  const copyMemeFile = useCallback(async () => {
    try {
      setIsCopying(true)
      setCopyImageAction(true)
      await saveMemeResult(
        session.access_token,
        resultId,
        captions,
        header,
        footer
      )
      toast.success('Meme copied to the clipboard.')
      setCopyImageAction(false)
    } catch (e) {
      Sentry.captureException(e)
      toast.error(
        'An error occurred while trying to copy the meme. Please try again.'
      )
    } finally {
      setIsCopying(false)
    }
  }, [memeFormat, downloadRef, imageSrc, watermark, captions, header, footer])

  const shareMemeFile = useCallback(async () => {
    setShareImageAction(true)
    await saveMemeResult(
      session.access_token,
      resultId,
      captions,
      header,
      footer
    )
    setShareImageAction(false)
  }, [memeFormat, downloadRef, imageSrc, watermark, captions, header, footer])

  const shareGifFile = useCallback(async () => {
    setIsSharing(true)
    const data = await createGIFBlob(
      imageSrc,
      downloadRef.current.clientWidth,
      downloadRef.current.clientHeight,
      watermark,
      header,
      footer,
      captions
    )

    if (!data) {
      throw new Error('Failed to get blob')
    }

    const file = new File([data], 'Supermeme.gif', { type: 'image/gif' })

    if (!navigator.share || navigator.canShare({ files: [file] }) === false) {
      await downloadGIF(data)
      setIsSharing(false)
      return
    }

    return navigator
      .share({
        files: [file],
      })
      .then(() => {
        toast.success('Meme shared successfully.')
      })
      .catch((error) => {
        Sentry.captureException(error)
        toast.error('Sharing was cancelled.')
      })
      .finally(() => {
        setIsSharing(false)
      })
  }, [memeFormat, downloadRef, imageSrc, watermark, captions, header, footer])

  return (
    <div className='space-y-4'>
      <div
        id={`supermeme-${resultId}`}
        className='flex min-h-full min-w-full items-center justify-center rounded-md p-0 md:p-4'
      >
        <div
          id={`meme-container-preview-${resultId}`}
          className={`relative flex flex-col max-w-[${maxDimension}px]`}
          ref={downloadRef}
          style={{
            width: `${imageWidth > maxDimension ? maxDimension : imageWidth}px`,
          }}
        >
          {memeFormat === 'image' && isDesktop && (
            <EditableMeme
              src={imageSrc}
              captions={captions}
              setCaptions={setCaptions}
              watermark={watermark}
              maxDimension={maxDimension}
              header={header}
              footer={footer}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              downloadImageAction={downloadImageAction}
              copyImageAction={copyImageAction}
              shareImageAction={shareImageAction}
            />
          )}

          {memeFormat === 'image' && !isDesktop && (
            <EditableMemeMobile
              src={imageSrc}
              captions={captions}
              setCaptions={setCaptions}
              watermark={watermark}
              maxDimension={maxDimension}
              header={header}
              footer={footer}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              downloadImageAction={downloadImageAction}
              copyImageAction={copyImageAction}
              shareImageAction={shareImageAction}
            />
          )}

          {memeFormat === 'gif' && (
            <EditableGif
              src={imageSrc}
              header={header}
              footer={footer}
              watermark={watermark}
              maxDimension={maxDimension}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
            />
          )}
        </div>
      </div>
      <div className='flex items-center justify-center space-x-2'>
        {isDesktop && memeFormat === 'image' && (
          <ImageDownloadButton
            isDownloading={isDownloading}
            facebookDownload={() => downloadMemeFile('facebook')}
            instagramDownload={() => downloadMemeFile('instagram')}
            linkedinDownload={() => downloadMemeFile('linkedin')}
            xDownload={() => downloadMemeFile('x')}
            defaultDownload={() => downloadMemeFile('default')}
          />
        )}

        {isDesktop && memeFormat === 'gif' && (
          <GifDownloadButton
            isDownloading={isDownloading}
            download={() => downloadGifFile()}
          />
        )}

        <Button size='sm' variant='outline' onClick={() => setIsEditing(true)}>
          <PenSquare className='mr-2 h-4 w-4' />
          Edit
        </Button>

        {isDesktop && !isSafari && memeFormat !== 'gif' && (
          <CopyButton isCopying={isCopying} onClick={copyMemeFile} />
        )}

        {!!isStarredMeme ? (
          <UnStarButton resultId={resultId} callback={starUnstarCallback} />
        ) : (
          <StarButton resultId={resultId} callback={starUnstarCallback} />
        )}

        {!isDesktop && memeFormat === 'image' && (
          <ShareButton isSharing={isSharing} onClick={shareMemeFile} />
        )}

        {!isDesktop && memeFormat === 'gif' && (
          <ShareButton isSharing={isSharing} onClick={shareGifFile} />
        )}
      </div>
      {isDesktop && memeFormat !== 'gif' && (
        <div className='flex items-center justify-center'>
          <span className='text-xs text-gray-600'>
            Drag and drop meme text to change text position and size
          </span>
        </div>
      )}

      {/* Editor dialog */}
      <Dialog
        open={isEditing}
        onClose={() => setIsEditing(false)}
        className='relative z-50'
      >
        <div className='fixed inset-0 z-20 bg-black/80' aria-hidden='true' />
        <div className='fixed inset-0 z-30 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center'>
            <Dialog.Panel className='relative mx-auto h-full w-full max-w-7xl rounded bg-white px-2 py-8 md:px-8'>
              <button
                className='absolute right-4 top-4'
                onClick={() => setIsEditing(false)}
              >
                <XIcon className='h-6 w-6 text-gray-700' />
              </button>

              <MemeEditor
                captions={captions}
                setCaptions={setCaptions}
                imageSrc={imageSrc}
                resultId={resultId}
                setResultId={setResultId}
                watermark={watermark}
                memeFormat={memeFormat}
                maxDimension={maxDimension}
                isDesktop={isDesktop}
                header={header}
                setHeader={setHeader}
                footer={footer}
                setFooter={setFooter}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                isStarredMeme={isStarredMeme}
                starUnstarCallback={starUnstarCallback}
              />
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default GeneratedMeme
