// React
import { useEffect, useState } from 'react'

// Next
import Image from 'next/legacy/image'
import Link from 'next/link'
import Head from 'next/head'
import { useRouter } from 'next/router'

import GeneratedMeme from '@/components/memes/templates/generated-meme'
import { AnimatedInput } from '@/components/blocks/animated-input'
import { CustomLoader } from '@/components/blocks/custom-loader'

// Layouts
import ProtectedPage from '@/components/layouts/protected-page'

// Utils
import { postData } from '@supermeme-ai/utils'
import {
  useRemainingCredits,
  useSession,
  useRefreshUsedCredits,
  useSubscription,
  useUserDetails,
  useWatermark,
} from '@/utils/auth/user-context'
import { classNames, shuffleArray } from '@supermeme-ai/utils'

// Types
import { Result, TextToMemeGeneratedResponse } from '@supermeme-ai/types'

import { useWindowSize } from 'react-use'
import { Switch } from '@headlessui/react'
import { toast } from 'react-hot-toast'
import { SparklesIcon } from 'lucide-react'

const exampleTexts = [
  'Spent 10 hours on social media marketing material and finally got 5 likes',
  'Just got a raise from the boss',
  'Just finished my final Phd dissertation',
  'Founders making their first money with their product',
  "I have no idea what I'm going to cook tonight",
  'I am exhausted because I spent 10 hours in meetings today',
  'Nobody really knows if crypto will go up or down',
  'Product management is a tricky balance between saying yes and no',
  'Programmers like to argue about what language is the best',
  'Helping customers to be successful will make your business successful',
]

const TextToMeme2: React.FC = () => {
  // context
  const session = useSession()
  const [userDetails] = useUserDetails()
  const subscription = useSubscription()
  const [watermark] = useWatermark()
  const remainingCredits = useRemainingCredits()
  const refreshRemainingCredits = useRefreshUsedCredits()

  // page state
  const [text, setText] = useState<string>('')
  const [results, setResults] = useState<Result[]>([])
  const [loading, setLoading] = useState(false)
  const [searchGIFs, setSearchGIFs] = useState(false)

  // other
  const router = useRouter()
  const { width: screenWidth } = useWindowSize()

  const maxDimension = screenWidth >= 500 ? 500 : window.innerWidth - 32

  const generateMemes = async (evt) => {
    evt.preventDefault()
    if (text) {
      setLoading(true)
      setResults([])
      const { data, error } = await postData<{
        response: TextToMemeGeneratedResponse
      }>(
        '/api/meme/text-to-meme-2',
        {
          text,
          maxDimension,
          inputLanguage: userDetails?.meme_input_language,
          outputLanguage: userDetails?.meme_output_language,
        },
        session.access_token
      )

      if (error) {
        console.error(error)
        toast.error(
          'An error occurred while generating memes. If the problem persists, please contact Supermeme support.'
        )
      }

      // console.log(data);

      if (data && data.response) {
        setResults(data.response.results)
        refreshRemainingCredits()
      }

      setLoading(false)
    }
  }

  const generateGIFs = async (evt) => {
    evt.preventDefault()
    if (text) {
      setLoading(true)
      setResults([])
      const { data, error } = await postData<{
        response: TextToMemeGeneratedResponse
      }>(
        '/api/meme/text-to-gif',
        {
          text,
          imageCount: 3,
          maxDimension,
          inputLanguage: userDetails?.meme_input_language,
          outputLanguage: userDetails?.meme_output_language,
        },
        session.access_token
      )

      if (error) {
        toast.error(
          'An error occurred while generating memes. If the problem persists, please contact Supermeme support.'
        )
      }

      if (data && data.response) {
        setResults(data.response.results)
        refreshRemainingCredits()
      }

      setLoading(false)
    }
  }

  useEffect(() => {
    const { prompt } = router.query
    if (prompt) {
      setText(prompt as string)
    }
  }, [router])

  return (
    <>
      <Head>
        <title>Turn text into advanced memes using AI | Supermeme.ai</title>
        <meta
          name='description'
          content='Enter any text input and let AI generate advanced multi-text and multipanel memes in 1-click! Try for free! No Credit Card Required.'
        />
      </Head>
      <>
        <div className='mx-auto space-y-6 md:container'>
          <h1 className='text-center text-2xl font-extrabold md:text-4xl'>
            <span className='block whitespace-nowrap md:inline'>
              Turn text into
            </span>{' '}
            <span className='block whitespace-nowrap md:inline'>
              high-quality memes or GIF
            </span>
          </h1>
          <form
            onSubmit={(evt) => {
              if (searchGIFs) {
                generateGIFs(evt)
              } else {
                generateMemes(evt)
              }
            }}
          >
            <div className='mx-auto max-w-4xl space-y-4 text-center'>
              <label
                htmlFor='text'
                className='w-full text-center text-sm font-semibold md:text-xl'
              >
                <span className='block whitespace-nowrap md:inline'>
                  Enter any text (tweet, blog post intro, etc.)
                </span>{' '}
                <span className='block whitespace-nowrap md:inline'>
                  and hit &quot;Generate&quot; to get relevant memes in
                </span>{' '}
                <span className='block whitespace-nowrap md:inline'>
                  <Link
                    href='https://www.supermeme.ai/search-supported-languages'
                    legacyBehavior
                  >
                    <a
                      className='whitespace-nowrap underline'
                      target='_blank'
                      rel='noreferrer nofollow'
                    >
                      110+ languages
                    </a>
                  </Link>
                  .
                </span>
              </label>

              <div className='flex flex-col items-start space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0'>
                <div className='flex w-full flex-col space-y-4'>
                  <AnimatedInput
                    id='text-to-meme'
                    name='text-to-meme'
                    label='Enter any text (tweet, blog post intro, etc.) and hit Generate to get relevant memes in 110+ languages'
                    placeholders={exampleTexts}
                    value={text}
                    required
                    maxLength={300}
                    buttonLabel='Generate'
                    buttonIcon={
                      <SparklesIcon
                        className='h-5 w-5 text-white'
                        aria-hidden='true'
                      />
                    }
                    buttonDisabled={!subscription && remainingCredits <= 0}
                    buttonType='submit'
                    loading={loading}
                    onChange={(value) => setText(value)}
                  />

                  {(!!subscription || remainingCredits > 0) && (
                    <div className='flex w-full justify-between px-1'>
                      <p className='mt-1 w-full text-left text-xs text-gray-500'>
                        <button
                          type='button'
                          className='underline'
                          onClick={() => {
                            const shuffledArray = shuffleArray(exampleTexts)
                            setText(shuffledArray[0])
                          }}
                        >
                          Try sample text
                        </button>
                      </p>
                      <div className='flex'>
                        <div className='flex items-center justify-center space-x-1 px-2'>
                          <span className='text-xs'>Images</span>
                          <Switch
                            checked={searchGIFs}
                            onChange={async (value) => {
                              setResults([])
                              setSearchGIFs(value)
                            }}
                            className='relative inline-flex h-[26px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-indigo-600 bg-indigo-600 transition-colors duration-200 ease-in-out focus:outline-none'
                          >
                            <span
                              aria-hidden='true'
                              className={`${searchGIFs ? 'translate-x-6' : 'translate-x-0'} pointer-events-none inline-block h-[22px] w-[22px] transform rounded-full bg-white shadow-lg transition duration-200 ease-in-out`}
                            />
                          </Switch>
                          <span className='text-xs'>GIFs</span>
                        </div>
                        <p className='mt-1 w-[120px] whitespace-nowrap text-right text-xs text-gray-500'>
                          {text?.length ?? 0} / 300
                        </p>
                      </div>
                    </div>
                  )}
                  {!subscription && remainingCredits <= 0 && (
                    <Link
                      className='mt-8 w-full max-w-full space-y-1 text-center text-sm'
                      href='/account/upgrade'
                    >
                      <span
                        className='text-center text-base font-bold text-indigo-600'
                        style={{ flex: 1 }}
                      >
                        You have no credits left. Please{' '}
                        <span className='font-bold text-indigo-600 underline'>
                          upgrade your subscription
                        </span>{' '}
                        to continue generating memes.
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </form>
          {loading && (
            <div className='flex items-center justify-center py-12'>
              <CustomLoader />
            </div>
          )}
          {results.length === 0 && !loading && (
            <div className='rounded-lg border-2 border-dashed border-gray-400 text-center'>
              <Image
                priority={true}
                alt={'Placeholder image'}
                src={'/static/empty-placeholder.png'}
                width={600}
                height={571.5}
              />
            </div>
          )}

          {results.length > 0 && (
            <div
              className={classNames(
                'grid grid-cols-1 gap-2 border-t border-gray-200 pt-6 lg:grid-cols-2 lg:gap-4',
                searchGIFs ? '2xl:grid-cols-3' : 'xl:grid-cols-2'
              )}
            >
              {searchGIFs && (
                <>
                  <div className='hidden lg:block'></div>
                  <div className='hidden 2xl:block'></div>
                  <div className='mx-auto lg:mx-0 lg:ml-auto'>
                    <Image
                      priority={false}
                      alt={'Giphy logo'}
                      src={'/static/giphy-logo.png'}
                      width={200}
                      height={22}
                    />
                  </div>
                </>
              )}
              {results.map((result) => (
                <div key={result.id} className='rounded-lg bg-gray-100 py-4'>
                  <GeneratedMeme
                    initialCaptions={result.captions}
                    memeFormat={searchGIFs ? 'gif' : 'image'}
                    watermark={watermark}
                    initialResultId={result.id}
                    imageSrc={result.image_name}
                    maxDimension={maxDimension}
                    initialHeader={result.header}
                    initialFooter={result.footer}
                    imageWidth={result.width}
                    imageHeight={result.height}
                    isStarredMeme={false}
                    starUnstarCallback={() => {}}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    </>
  )
}

export default ProtectedPage(TextToMeme2)
